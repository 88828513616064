export const CONSULTAR_FABRICA_DESCRICAO = "Consultar Fábrica"

export const TIPO_ANEXO = {
    PEDIDO:                       { value: 1, description: 'PEDIDO' },
}

export const TIPO_DOCUMENTO = {
    ORDEM_COMPRA:                 { value: 1, description: 'ORDEM DE COMPRA' },
    EVIDENCIA_PRECO:              { value: 2, description: 'EVIDÊNCIA DE PREÇO' },
    OUTROS:                       { value: 3, description: 'OUTROS' },
}

export const LIST_TIPO_PEDIDO = [
    { id: "F", descricao: "Consumidor Final"},
    { id: "L", descricao: "Produto Rural"},
    { id: "R", descricao: "Revendedor"},
    { id: "S", descricao: "Solidário"},
    { id: "X", descricao: "Exportação"},
]

export const LIST_TIPO_CLIENTE = [
    { id: 1, descricao: 'OEM'},
    { id: 2, descricao: 'Distribuidor'},
    { id: 3, descricao: 'Usuario Final'},
    { id: 4, descricao: 'Revenda'},
    { id: 5, descricao: 'Key Account'},
]

export const LIST_TIPO = [
    { id: 'F', descricao: 'Cons. Final'},
    { id: 'L', descricao: 'Prod. Rural'},
    { id: 'R', descricao: 'Revendedor'},
    { id: 'S', descricao: 'Solidário'},
    { id: 'X', descricao: 'Exportação'},
]

export const LIST_TIPO_CLIENTE_CADASTRO = [
    { id: 'END', descricao: 'End User'},
    { id: 'OEM', descricao: 'OEM'},
    { id: 'RES', descricao: 'Resale'},
]